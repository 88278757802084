$f: 'Acrom', sans-serif;
$f2: 'Gilroy', sans-serif;
$fa: 'FontAwesome';

$tc: #009bde !default;
$bk: #00284f !default;
$wh: #fff !default;

$dg: #3a3a3a !default;
$mg: #a8b4bf !default;
$lg: #f0f0f0 !default;
$bc: #ccd4dc !default;
$gb: #a8b4bf !default;
$lb: #f0f9fd !default;

$new: #00833d !default;
$special: #f92626 !default;

@mixin fade() {
	@include transition(all 0.2s ease-in-out);
}

@mixin flex() {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}