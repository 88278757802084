@media (max-width: $screen-lg-max) {
	.pav-megamenu .subcat .catgrid {
		flex-wrap: wrap;
		&_element {
			flex: 0 1 25%;
			text-align: center;
		}
	}
	.category_widget .type2 .img {
		h3 {
			font-size: 36px;
		}
	}
	.homebuilder .catgrid_element {
		flex: 1 25%;
	}
	.product-grid .product-block, .products-owl-carousel .product-block {
		width: calc(100% / 3 - 35px);
	}
}


@media (max-width: 1300px) {
	.newsletter {
		.fl_img {
			display: none;
		}
	}
	.product-info {
		.row {
			.product-row {
				.btn-container {
					flex-direction: column;
				}
			}
		}
	}
}


@media (max-width: $screen-md-max) {
	.footer-center .main-footer {
		margin-left: 0;
	}
	.pav-megamenu .navbar-nav > li {
		&:first-child {
			& > a {
				padding-left: 0;
			}
		}
		&:first-child {
			& > a {
				padding-right: 0;
			}
		}
		& > a {
			padding: 25px 10px;
			font-size: 14px;
		}
	}
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
		.row .product-row {
			flex-direction: column;
			gap: 16px;
		}
	}
	.header-top .topcontacts.phone {
		flex: 0 1 230px;
	}
	.header-bottom {
		.logo-container {
			flex: 0 0 170px;
		}
		.nav-search {
			margin-bottom: 7px;
		}
		#searchblock {
			width: 300px;
		}
	}
	.category_widget {
		.type1 .img h3 {
			font-size: 18px;
		}
		.type2 .img {
			h3 {
				padding: 20px;
				font-size: 28px;
			}
		}
	}
	.product-grid .product-block, .products-owl-carousel .product-block {
		width: calc(50% - 35px);
	}
	.pav-megamenu .dropdown-menu li a {
		padding: 10px 15px 10px 0;
		font-size: 16px;
	}
}

@media (max-width: $screen-md-max) and (min-width: $screen-md) {
	.home3 {
		.widget-blogs {
			.carousel-controls-v1 {
				top: 45px;
				.carousel-control.left {
					left: -65px;
				}
				.carousel-control {
					border: none;
				}
			}
		}
	}
}

/*$media (max-width: 991px) {*/
@media (max-width: $screen-sm-max) {
	.homebuilder .catgrid_element {
		display: none;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		.panel {
			margin-bottom: 15px;
		}
	}
	.checkout-buy #sidebar-main {
		padding-right: 8px;
	}
	#pavo-footer-center {
		.container {
			padding-right: 25px;
			.footer-left {
				float: none;
				margin: 0 auto 30px auto;
			}
		}
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.zoomContainer {
		display: none;
	}
	#products .products-block {
		margin-right: -15px;
	}
	#offcanvasmenu .catgrid_element img {
		display: none;
	}
	.product-grid {
		.product-block {
			width: calc(50% - 15px);
			margin-right: 15px;
			margin-bottom: 15px;
			.action > div {
				padding-right: 2px;
			}
			.product-meta {
				margin-bottom: 25px;
				.name {
					margin: 15px;
				}
				.price {
					padding: 15px 0;
					margin: 0;
					&:before {
						top: -5px;
					}
				}
			}
		}
	}
	.header-top {
		.topcontacts, .topcontacts.phone {
			flex: 0 0 250px;
			padding-left: 0;
			a {
				color: $gb !important;
			}
			.media-title {
				padding: 7px 10px;
			}
			.media-body {
				span {
					vertical-align: baseline;
					font-size: 12px;
					color: $gb !important;
				}
			}
		}
		.topcontacts svg {
			height: 40px;
		}
	}
	#powered {
		.copyright {
			font-size: 14px;
		}
		.payment-img {
			img {
				&:first-child {
					display: none;
				}
				&:last-child {
					display: block;
				}
			}
		}
	}
	.s24 {
		width: 90px;
	}
	.header-bottom {
		.main-menu {
			.pav-megamenu {
				.btn-primary {
					padding: 0 13px;
					&:hover {
						background: $tc;
					}
					span {
						font-size: 18px;
						vertical-align: text-bottom;
					}
				}
			}
		}
		.nav-search {
		}
		.logo-container {
			flex: 1 1 auto;
			text-align: center;
		}
		#searchblock {
			width: 700px;
		}
	}
	.pagination > li > a, .pagination > li > span {
		margin-bottom: 8px;
	}
	.tree-menu ul li:hover {
		background-color: $white;
		a {
			color: $bk;
		}
	}
	.breadcrumbs {
		padding: 15px 0;
	}
	.category_widget .type2 .img h3 {
		padding: 15px;
		font-size: 22px;
	}
	.cat_all {
		padding: 0 0 0 15px !important;
		& > li {
			position: relative;
			.click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 0 5px;
				font-size: 18px;
				line-height: 14px;
				top: 8px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $bk;
			}
			&.haschildren {
				i:before {
					content: '\f105'
				}
				ul {
					display: none;
				}
				& > a {
					width: calc(100% - 25px);
					display: inline-block;
				}
				&.opened {
					ul {
						display: block;
					}
					i:before {
						content: '\f107'
					}
				}
			}
			ul.children {
				li {
					padding: 0;
				}
			}
		}
	}
	#adv_main {
		padding: 10px 0;
		flex-wrap: wrap;
		.advmain {
			flex: 1 1 50%;
			padding: 10px 25px
		}
	}
	.category_widget {
		.type1 {
			h3 {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(25% - 20px);
	}
	.product-info {
		.title-product {
			font-size: 30px;
			padding: 20px 0 20px 0;
		}
	}
	.refine-search ul li a span, .refine-search ol li a span {
		font-size: 14px;
	}
	.desc-header-related {
		text-align: left !important;
		& > .container {
			position: static;
		}
		.scroll-button {
			right: 30px;
		}
	}
}

@media (max-width: $screen-sm) {
	.alert {
		margin: 10px 15px;
	}
	.quickview, .zoom {
		display: none !important;
	}
}

@media (max-width: $screen-xs-max) {
	.vm--modal {
	    max-width: 90% !important;
	    left: 5% !important;
	}
	.checkbox_custom .input-control {
		float: left;
	}
	.checkbox_custom.secure_payment {
		float: left;
	}
	.table-description {
		margin-left: 0;
	}
	.category_widget .type1 .img {
		margin-bottom: 15px;
		h3 {
			height: auto;
		}
	}
	.checkout-buy #sidebar-main {
		padding-right: 12px;
	}
	.product-block .bottom .action {
		&.btn-cart {
			width: calc(100% - 80px);
			left: 0;
		}
		&.btn-oneclick {
			display: none;
		}
	}
	.category_widget .type2 .img h3 {
		white-space: normal;
		font-size: 5vw;
	}
	.logo-container {
		width: 140px;
	}
	.featured-category ul li {
		width: 100%;
	}
	#powered {
		ul {
			&.links {
				margin-bottom: 40px;
			}
		}
		.inner {
			justify-content: center;
			flex-wrap: wrap;

			.copyright {
				order: 2;
				margin-bottom: 15px;
			}
			.payment {
				order: 1;
			}
		}
	}
	#adv_main {
		padding: 10px 0;
		flex-wrap: wrap;
		.advmain {
			flex: 0 0 100%;
			padding: 10px 25px
		}
	}
	#tab-review .desc-pane {
		padding: 15px 28px;
	}
	.desc-header-related {
		& > .container {
			text-align: center;
			position: relative;
		}
		.desc-header {
			margin: 0 15px;
			padding-bottom: 0;
		}
		.scroll-button {
			position: relative;
			right: 0;
			top: 0;
			float: right;
			margin: 15px;
		}
	}
	.widget-products .owl-stage {
		padding: 15px 0;
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}

	.checkout-cart table, .shopping-cart-table {
		background: #fff;
	}
	.shopping-cart-table {
		& > thead {
			display: none;
		}
		.shopping-cart-table__product-image img {
			max-width: 160px;
		}
		td.image-col {
			width: 100%;
			text-align: center !important;
		}
		&, & tbody, & td, &.tr {
			display: block;
			border: 0;
			width: 100%;
		}
		tr {
			display: block;
			td {
				position: relative;
				padding-left: 43% !important;
				text-align: left !important;
				white-space: normal !important;
				&:first-child {
					border-top: 0;
				}
				div.th-title {
					display: block;
					position: absolute;
					left: 10px;
					width: 38%;
					padding-right: 10px;
					text-align: right;
					top: 50%;
					@include transform(translateY(-50%));
					font-weight: bold;
					text-transform: uppercase;
				}
				&.no-title {
					padding-left: 20px !important;
					text-align: center !important;
				}
			}
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
	}

	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}

	.shopping-cart-btns {
		text-align: center;
		.btn.pull-left, .btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}
	#coupon_form {
		width: calc(100% - 150px);
		display: inline-block;
		top: 1px;
		position: relative;
		.form-group {
			display: inline-block;
		}
		#button-coupon {
			top: -1px;
		}
	}
	.header-top {
		.topcontacts {
			flex: 1 1 100%;
			padding: 0 15px;
		}
	}
	.header-bottom {
		height: 90px;
		> .container {
			flex: 1;
		}
		.header-panel {
			flex: 0 1 200px;
			top: calc(50% - 12px);
		}
		.main-menu {
			flex: 1;
		}
		.headercart .cart-total {
			display: none;
		}
		#searchblock {
			width: calc(100vw - 68px);
			height: 72px;
			top: -24px;
		}
		#searchtop {
			top: 18px;
		}
	}
	.logo-store {
		font-size: 12px;
		img {
			max-height: 40px;
		}
	}
	.canvas-menu {
		top: -60px;
	}
	.footer-center {
		.footer-left {
			ul {
				margin: 0 auto;
				li {
					max-width: 220px;
					margin: 0 auto;
				}
			}
		}
		.main-footer {
			flex-wrap: wrap;
			.box {
				width: 100%;
			}
		}
	}
	.h1_container h1 {
		padding: 13px 15px;
		font-size: 32px;
	}
	.producttabs .tab-header {
		font-size: calc(4vw + 14px);
		height: calc(215px + (100vw - 320px) / 25);
	}
	.product-info {
		.tab-content {
			.desc-header-wrapper .desc-header {
				font-size: 26px;
			}
			#tab-description, #tab-downloads {
				font-size: 14px;
				ul {
					padding-left: 10px;
				}
			}
			#tab-description .desc-pane {
				padding: 15px;
			}
			.attribs {
				.arow {
					div {
						font-size: 14px;
						padding: 5px 10px;
					}
					.asingle {
						padding: 5px 10px;
					}
					.acol {
						padding-left: 10px;
					}
				}
			}
		}
		.image-container {
			padding-bottom: 0;
		}
		.product-view {
			padding-right: 16px;
		}
	}
	.product-markdown-view {
		.general {
			.price-new {
				font-size: 30px;
			}
		}
		.cheaper-item {
			&:not(.general) {
				.price-new {
					font-size: 22px;
				}
			}
		}
	}
	.product-filter > .container {
		padding: 15px 30px;
		.sortlimit > div {
			& > span {
				font-size: 14px;
			}
			.selectric .label {
				padding: 8px 0;
				font-size: 14px;
			}
			&.limit {
				display: none;
			}
		}
	}
	#products {
		.products-block {
			padding: 0 15px;
		}
	}
	.category_widget .type2 .img {
		height: 43vw;
	}
	.holiday_works {
		left: 45px;
	}
	.fancy_holiday_works {
		width: 90% !important;
		.fancybox-inner {
			width: 100% !important;
		}
	}
	.holiday_table {
		width: 100%;
		td {
			font-size: 13px;
		}
	}
	.fancybox-skin {
		padding: 10px !important;
	}
	.header-top {
		.topcontacts {
			.media-body {
				&.phone, &.worktime {
					svg {
						vertical-align: middle;
					}
					span {
						font-size: initial;
					}
				}
			}
		}
	}
	.advantages-home {
		.owl-nav {
			.owl-prev {
				left: 0;
			}
			.owl-next {
				right: 0;
			}
		}
	}
}

@media (max-width: 637px) {
	.header-top .topcontacts .media-body.worktime {
		display: none;
	}
	.header-bottom.hw {
		height: 105px;
	}
	.holiday_works {
		left: 0;
		top: auto !important;
		right: 0;
		bottom: -17px;
	}
	.success {
		width: 300px;
		margin-left: -150px;
		padding: 30px 15px 15px 15px;
		.success_button {
			padding: 6px;
			font-size: 11px;
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(33.3% - 20px);
	}
	.logo-store a {
		margin: 0 auto 0 40px;
	}
	.newsletter {
		.nl_header {
			font-size: 28px;
		}
	}
}

@media (max-width: 570px) {
	.owl-dots {
		margin-top: 17px;
	}
	#powered > .container {
		padding: 0;
	}
	.header-top .topcontacts.worktime {
		display: none;
	}
	.header-top .topcontacts.phone {
		flex: 0 0 155px;
	}
	.header-bottom .nav-search {
		flex: 0 1 30px;
		margin-right: 15px;
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
	.catwidget_header h2 {
		font-size: 40px;
		margin-top: 30px;
		margin-bottom: 15px;
	}
}

@media (max-width: 510px) {
	.producttabs .owl-item {
		padding: 15px 0;
	}
	.h1_container h1 {
		font-size: 22px;
	}
}

@media (max-width: $screen-xs) {
	.category_widget .type2 .img h3 {
		font-size: 24px;
	}
	.product-filter .filter-right > div {
		margin-bottom: 5px;
		&.limit {
			display: none;
		}
	}
	.product-list {
		.product-col {
			.block-img {
				float: none;
				width: 100%;
			}
		}
		.product-meta {
			padding: 20px;
		}
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
	.pav-blogs .blog-body {
		overflow: visible;
	}
	.footer-center {
		font-size: 14px;
	}
	.product-grid {
		.product-block {
			width: 100%;
			.product-meta .name {
				margin: 15px 15px 0 15px;
			}
		}
	}
	#partnerpricemodule {
		padding: 30px 15px;
		h3 {
			font-size: 20px;
		}
		button {
			padding: 0 12px;
			font-size: 18px;
		}
	}
	.header-bottom {
		.product-compare-header, .headercart {
			position: absolute;
			top: -65px;
		}
		.product-compare-header {
			right: 50px;
			svg {
				fill: $wh;
			}
		}
		.headercart {
			#cart svg {
				path, circle {
					stroke: $wh;
				}
			}
		}
	}
}

@media (max-width: 360px) {
	.category_widget {
		.type1 {
			h2 {
				a {
					font-size: 13px;
					padding: 28px 0;
				}
			}
		}
	}
	.col-md-12 .refine-search ul li, .col-md-12 .refine-search ol li {
		width: calc(50% - 20px);
	}
	.mob_hidden_phone {
		display: none;
	}
	#coupon_form {
		width: calc(100% - 110px);
	}
	#button-coupon {
		font-size: 15px;
		padding: 0 6px;
	}
}