@font-face {
	font-family: 'Acrom';
	src: url('/fonts/acrom/subset-Acrom-Medium.eot');
	src: url('/fonts/acrom/subset-Acrom-Medium.eot?#iefix') format('embedded-opentype'),
	url('/fonts/acrom/subset-Acrom-Medium.woff2') format('woff2'),
	url('/fonts/acrom/subset-Acrom-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Acrom';
	src: url('/fonts/acrom/subset-Acrom-Bold.eot');
	src: url('/fonts/acrom/subset-Acrom-Bold.eot?#iefix') format('embedded-opentype'),
	url('/fonts/acrom/subset-Acrom-Bold.woff2') format('woff2'),
	url('/fonts/acrom/subset-Acrom-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Acrom';
	src: url('/fonts/acrom/subset-Acrom-ExtraBold.eot');
	src: url('/fonts/acrom/subset-Acrom-ExtraBold.eot?#iefix') format('embedded-opentype'),
	url('/fonts/acrom/subset-Acrom-ExtraBold.woff2') format('woff2'),
	url('/fonts/acrom/subset-Acrom-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Acrom';
	src: url('/fonts/acrom/subset-Acrom-Thin.eot');
	src: url('/fonts/acrom/subset-Acrom-Thin.eot?#iefix') format('embedded-opentype'),
	url('/fonts/acrom/subset-Acrom-Thin.woff2') format('woff2'),
	url('/fonts/acrom/subset-Acrom-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Acrom';
	src: url('/fonts/acrom/subset-Acrom-Regular.eot');
	src: url('/fonts/acrom/subset-Acrom-Regular.eot?#iefix') format('embedded-opentype'),
	url('/fonts/acrom/subset-Acrom-Regular.woff2') format('woff2'),
	url('/fonts/acrom/subset-Acrom-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Acrom';
	src: url('/fonts/acrom/subset-Acrom-Light.eot');
	src: url('/fonts/acrom/subset-Acrom-Light.eot?#iefix') format('embedded-opentype'),
	url('/fonts/acrom/subset-Acrom-Light.woff2') format('woff2'),
	url('/fonts/acrom/subset-Acrom-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'gilroy';
	src: url('/fonts/gilroy/subset-Gilroy-ExtraBold.woff2') format('woff2'),
	url('/fonts/gilroy/subset-Gilroy-ExtraBold.woff') format('woff'),
	url('/fonts/gilroy/subset-Gilroy-ExtraBold.ttf') format('truetype'),
	url('/fonts/gilroy/subset-Gilroy-ExtraBold.svg#gilroyextrabold') format('svg');
	font-weight: normal;
	font-style: normal;
}
