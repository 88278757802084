.owl-carousel {
	display: block !important;
}

.s24 {
	position: absolute;
	right: 0;
	top: 20px;
}

.product-block .s24 {
	width: 27% !important;
}

.category-info {
	padding: 0;
	.image {
		padding: 0;
		margin-bottom: 19px;
	}
}

.filter-right {
	& > div {
		@include flex();
		align-items: center;
	}
	.sort {
		margin-left: 15px;
	}
	label {
		line-height: 14px;
	}
}

.refine-search {
	margin-top: 15px;
	ul, ol {
		list-style: none;
		@include flex();
		flex-wrap: wrap;
		li {
			padding: 5px 0;
			margin-right: 10px;
			margin-left: 10px;
			line-height: normal;
			position: relative;
			width: calc(25% - 20px);
			a {
				@include flex();
				flex-wrap: wrap;
				justify-content: center;
				color: $bk;
				align-items: center;
				padding: 6%;
				img {
					max-width: calc(100% - 45px);
				}
				span {
					padding: 10px 0;
					display: inline-block;
					text-align: center;
					font-weight: 700;
					font-size: 18px;
					width: 100%;
				}
			}
			@media (max-width: 767px) {
				width: calc((100% / 3) - 20px);
			}
			@media (max-width: 500px) {
				width: calc((100% / 2) - 20px);
			}
			@media (max-width: 400px) {
				font-size: 12px;
			}
		}
	}
	h3 {
		color: #474747;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}
}

.col-md-12 {
	.refine-search ul li, .refine-search ol li {
		width: calc(20% - 20px);
	}
}

.style-panel {
	.panel {
		margin: 15px 0;
		border: 1px solid #ddd;
	}
	.panel-collapse {

	}
	.panel-heading {

	}
}

.information-contact {
	.lcol, .rcol {
		width: 50%;
	}
	#all_info {
		h5 {
			font-size: 18px;
			font-weight: 600;
		}
	}
	.contacts_map {
		display: flex;
		.lcol {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				background: $wh;
				height: 1px;
				width: 75%;
				bottom: 0;
				left: 65px;
			}
			h1 {
				text-align: left;
				margin-top: 20px;
			}
			ul {
				padding: 0;
				list-style: none;
			}
			li {
				padding: 20px 0 20px 65px;
				background: top 30px left no-repeat transparent;
				background-size: 40px auto;
				position: relative;
				span {
					font-size: 18px;
					line-height: 20px;
					display: block;
				}
				&:after {
					content: '';
					position: absolute;
					background: $wh;
					height: 1px;
					width: 75%;
					top: 3px;
				}
				&:first-child {
					&:after {
						display: none;
					}
				}
			}
			@for $i from 6 through 10 {
				li:nth-child(#{$i - 5}) {
					background-image: url(/image/i/contact/#{$i}.PNG);
				}
			}
		}
		.rcol {
			height: 700px;
		}
	}
	.proezd {
		display: flex;
		.lcol {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				background: $wh;
				height: 1px;
				width: 75%;
				bottom: 0;
				left: 65px;
			}
		}
		.rcol {
			background: url(/image/enter.jpg) center center no-repeat;
			background-size: cover;
			height: 430px;
		}
		h2 {
			font-size: 36px;
			text-align: left;
			margin-top: 40px;
			margin-bottom: 80px;
			font-weight: 600;
		}
		.ped, .avto {
			@include size(170px, 170px);
			background: $wh top 30px center no-repeat;
			background-size: 45px;
			text-align: center;
			display: inline-block;
			padding: 100px 10px 0 10px;
			font-size: 12px;
			vertical-align: bottom;
		}
		.ped {
			margin-left: 65px;
			margin-right: 30px;
			background-image: url(/image/i/contact/ped.PNG)
		}
		.avto {
			background-image: url(/image/i/contact/avto.PNG)
		}
	}
	.proezd2 {
		position: relative;
		padding-bottom: 75px;
		&:after {
			content: '';
			position: absolute;
			background: $wh;
			height: 1px;
			width: 85%;
			bottom: 0;
			left: 65px;
		}
		h5 {
			padding: 60px 0 35px 0;
			font-weight: 600;
		}
		p, h5 {
			font-size: 18px;
		}

	}
	form.content-contact {
		h2 {
			font-size: 36px;
			text-align: left;
			margin: 40px 0;
			font-weight: 600;
		}
		.button1 {
			float: right;
			color: $white;
			font-size: 14px;
			position: absolute;
			bottom: 16px;
			right: 0;
			padding: 6px 52px;
		}
	}
	.form-horizontal .form-group {
		padding: 15px;
		margin: 0;
	}
	@media (max-width: 1199px) {
		.proezd {
			.ped {
				margin-left: 30px;
			}
		}
	}
	@media (max-width: 991px) {
		.proezd {
			.lcol div {
				margin: 0;
			}
		}
	}
	@media (max-width: 767px) {
		.contact-info > div {
			flex-wrap: wrap;
			#map {
				height: 400px;
			}
		}
		.lcol, .rcol {
			width: 100%;
		}
		.proezd {
			h2 {
				margin-bottom: 40px;
			}
			.lcol > div {
				width: 100%;
				height: auto;
				min-height: 50px;
				background-position: center left 5px;
				margin-bottom: 10px;
				text-align: left;
			}
			.ped {
				padding: 20px 10px 20px 60px;

			}
			.avto {
				padding: 10px 10px 10px 60px;
			}
		}
		.proezd2 {
			padding-bottom: 40px;
			h5 {
				padding: 30px 0 15px 0;
			}
		}
		form.content-contact .button1 {
			position: static;
		}
	}
}

.sidebar {
	.products-block {
		border: $brand-border;
		@include rounded-corners(3px);
	}
	.product-label {
		display: none;
	}
	.product-col {
		width: 100%;
		clear: both;

	}
	.products-row {
		&:last-child {
			.product-block {
				border-bottom: none;
			}
		}
	}
	.price .price-old {
		font-size: $font-size-base - 1;
	}
	.price .price-new {
		font-size: $font-size-base;
	}
	.product-block {
		overflow: hidden;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.image {
			padding: 0;
		}
		.block-img {
			float: left;
			margin-right: 10px;

		}
		.top {
			padding: 0;
		}
		.product-meta {
			overflow: hidden;
			text-align: left;
			padding: 0;
		}
		.name {
			padding-top: 0;
			margin-bottom: 0;
		}
	}
	.bottom {
		display: none;
	}
	.product-grid {
		.product-block {
			padding: 15px;
			&:hover {
				.cart, .top {
					margin: 0;
				}
				.image {
					@include opacity(1);
				}
			}
		}
	}
}

.product-block {
	.image {
		position: relative;
		img {
			padding: 15px;
		}
	}
	.bottom {
		@include flex();
		z-index: 999;
		height: 40px;
		margin: 0 20px;
		padding: 0;
		text-align: center;
		position: relative;
		.action {
			width: 64px;
			height: 40px;
			padding: 0;
			position: absolute;
			svg {
				width: 28px;
				height: 28px;
				fill: $tc;
				stroke: none;
				stroke-width: 1px;
				margin: 4px 20px;
			}
			&.btn-default {
				overflow: hidden;
				z-index: 50;
				&:hover, &active, &:focus {
					border-color: $tc;
					color: $tc;
					width: 100%;
					z-index: 51;
				}
				.svgcontainer {
					position: absolute;
					z-index: 3;
					background: $wh;
					width: 64px;
					height: 100%;
					display: inline-block;
					top: 0;
				}
				.captcontainer {
					position: absolute;
					top: 0;
					width: calc(100% - 64px);
				}
				span {
					text-transform: none;
					letter-spacing: -1px;
				}
			}
			&.btn-cart {
				width: calc(100% - 168px);
				left: 84px;
				right: 84px;
			}
			&.cart-nostock {
				font-size: 15px;
				left: 0;
				width: calc(100% - 84px);
			}
			&.btn-oneclick {
				left: 0;
				.svgcontainer {
					left: -2px;
				}
				.captcontainer {
					right: 0;
				}
			}
			&.btn-compare {
				right: 0;
				.svgcontainer {
					right: -2px;
				}
				.captcontainer {
					left: 0;
				}
			}
		}
	}
	@media (min-width: 992px) {
		img {
			@include fade();
		}
		.quickview {
			display: inline-block;
			position: absolute;
			top: calc(50% - 20px);
			left: calc(50% - 110px);
			z-index: 10;
			padding: 3px 0;
			border: 2px $tc solid;
			border-radius: 3px;
			color: $tc;
			font-size: 18px;
			font-weight: 500;
			width: 220px;
			height: 40px;
			background: $wh;
			opacity: 0;
			visibility: hidden;
			@include fade();
		}
		.image {
			&:hover {
				.quickview {
					opacity: 1;
					visibility: visible;
				}
				img {
					filter: blur(7px);
				}
			}
		}
	}
}

.product-info {
	.product-meta {
		.btn-sm, .btn-group-sm > .btn {
			padding: 0 15px;
		}
	}
}

.subcategories {
	margin-bottom: -1px;
	background-color: $product-filter-bg;
}

.product-filter {
	background: $wh;
	& > .container {
		@include flex();
		justify-content: space-between;
		padding: 15px;
		.sortlimit {
			flex: 1;
			&, & > div {
				@include flex();
			}
			& > div {
				align-items: center;
				margin-right: 30px;
				& > span {
					margin-right: 5px;
					font-size: 18px;
					font-weight: 500;
				}
				.selectric {
					background: transparent;
					border: 0;
					.label {
						padding: 6px 0;
						font-size: 18px;
						font-weight: 700;
						color: $tc;
					}
				}
			}
		}
		.form-control {
			display: inline-block;
			padding: 0 20px;
			margin: 0 5px;
			@include size(auto, 36px);
			text-transform: capitalize;
		}
		.btn-switch {
			display: inline-block;
			margin-right: 5px;
			background: transparent;
			padding: 8px 5px 0 0;
			svg {
				fill: $tc;
				stroke: none;
				@include transition (all 200ms ease 0s);
				width: 24px;
				height: 24px;
				opacity: .5;
			}
			&:hover, &.active {
				svg {
					opacity: 1;
				}
			}
		}
	}
}

#products {
	.products-block {
		@include flex();
		flex-wrap: wrap;
		align-items: stretch;
		margin-right: -35px;
	}
	&.product-list {
		.product-block {
			margin-right: 0;
		}
	}
}

.product-list {
	.description {
		display: block;
	}
	.product-meta {
		padding-left: 15px;
	}
	.rating {
		position: relative;
		top: 20px;
	}
	.listprice {
		display: block;
		flex: 1 0 250px;
		position: relative;
		margin-left: 25px;
		&:before {
			content: '';
			position: absolute;
			width: 1px;
			height: calc(100% - 50px);
			background: $lg;
			left: 0;
			top: 25px;
			bottom: 0;
		}
		.price {
			display: flex;
			align-content: center;
			justify-content: center;
			flex-direction: column;
			align-items: flex-start;
			flex-wrap: wrap;
			height: 100%;
			padding: 0 25px;
		}
		span {
			width: 100%;
		}
		.price-old {
			font-size: 14px;
			font-weight: 300;
		}
		.price-new {
			font-size: 36px;
			font-weight: 800;
			line-height: 1;
		}
	}
	.product-block {
		@include flex();
		background: $wh;
		border-radius: 5px;
		margin-bottom: 35px;
		&:last-child {
			border-bottom: 1px solid $bc;
		}
		.quickview {
			padding: 0;
			font-size: 16px;
			line-height: 34px;
			width: 190px;
			left: calc(50% - 95px);
		}
		.top {
			.price {
				display: none;
			}
		}
		.name {
			font-weight: 300;
			font-size: 18px;
			b {
				text-transform: uppercase;
				margin-bottom: 5px;
				display: inline-block;
			}
		}
		.block-img {
			width: 30%;
			padding: 15px;
		}
		.bottom {
			width: 298px;
			margin: 0;
			justify-content: flex-start;
			.action {
				display: inline-block;
				&.btn-cart {
					width: 130px;
				}
			}
			.cart {
				flex: 0;
				button {
					padding: 0 30px;
				}
				display: inline-block;
			}
		}
	}
}

.product-grid, .products-owl-carousel {
	&.product-related {
		.products-block {
			padding: 15px 0;
		}
		.product-block {
			width: calc(100% / 4 - 33px);
		}
	}
	.listprice {
		display: none;
	}
	.product-block {
		display: inline-block;
		width: calc(25% - 35px);
		background: $wh;
		margin-right: 35px;
		margin-bottom: 35px;
		@include rounded-corners(0);
		@include transition(transform 0.2s ease-in-out);
		.description {
			display: none;
		}
		.name {
			font-size: 18px;
			font-weight: 300;
			margin: 0 30px 10px;
			text-align: center;
			a {
				padding-bottom: 10px;
				display: inline-block;
			}
			b {
				font-weight: 800;
			}
			.rating {
				text-align: center;
				padding-bottom: 10px;
				font-size: 14px;
			}
		}
		.product-meta {
			margin-bottom: 25px;
		}
		.price {
			text-align: center;
			line-height: 1;
			margin-bottom: 25px;
			margin-top: 20px;
			@include flex();
			flex-wrap: wrap;
			justify-content: center;
			align-items: flex-end;
			display: inline-block;
			width: 100%;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				height: 1px;
				background: $bc;
				top: -20px;
				left: 30px;
				right: 30px;
			}
			&-new {
				font-weight: 800;
				font-size: 36px;
				display: inline-block;
				.cur {
					font-weight: 800;
				}
			}
			&-old {
				font-size: 16px;
				display: block;
				text-align: left;
				opacity: .5;
			}
		}
		.image {

		}
	}
}

.products-owl-carousel {
	.owl-stage-outer {
		padding: 15px 0;
	}
	.product-block {
		width: auto;
	}
}

.rating, .stars {
	.fa {
		color: #ffbb00;
	}
}

.h1_container {
	font-size: 26px;
	background: $tc;
	h1 {
		font-size: 40px;
		font-weight: 800;
		text-transform: uppercase;
		padding: 24px 0;
		color: $wh;
	}
}

.horital {
	position: relative;
}

.owl-carousel {
	.owl-wrapper-outer {
		border: none;
		@include box-shadow(none);
		@include rounded-corners(0);
	}
}

.related {
	.owl-item {
		.product-col {
			border-left: 1px solid $border-color;
		}
		&:first-child {
			.product-col {
				border-left: 1px solid transparent;
			}
		}
	}
}

#input-captcha, #input-payment-captcha {
	margin-bottom: 10px;
}
//icons product
.advantages_block {
	display: none;
	flex-wrap: wrap;
	.advantage_item	{
		display: flex;
		align-items: center;
		width: 50%;
		& > div {
			margin-right: 10px;
			& > svg {
				@include size(75px, 75px);
			}
		}
		span {
			font-size: 13px;
		}
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.advantages_block .advantage_item	{
		width: 100%;
		span {
			font-size: 14px;
		}
		& > div {
			& > svg {
				@include size(90px, 90px);
			}
		}
	}
}
@media (max-width: 767px) {
	.advantages_block .advantage_item {
		display: none;
	}
}
@media (min-width: 1400px) {
	.advantages_block .advantage_item {
		display: none;
	}
}