.close {
	float: right;
	font-size: ($font-size-base * 3);
	font-weight: $close-font-weight;
	line-height: 1;
	color: $close-color;
	text-shadow: $close-text-shadow;
	@include opacity(.2);
	margin: 0 5px;
	&:hover, &:focus {
		color: $close-color;
		text-decoration: none;
		cursor: pointer;
		@include opacity(.5);
	}
}

button.close {
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
}
