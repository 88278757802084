
.page-themecontrol-product {
	#page {
		min-height: 1px;
	}
}

#button-cart {
	padding: 0 37px;
}

.main-title {
	position: absolute;
	left: -9999rem;
	top: -9999rem;
}

#tab-description {
	img {
		height: auto;
	}
}

#tab-downloads {
	ul li {
		list-style: none;
	}
}

#input-topic {
	list-style: none;
	padding: 0;
}

#mainmenutop {
	.nav.navbar {
		height: 60px;
	}
	.panel-v4 .panel-body {
		border: none !important;
	}
}

.contact-blocks {
	display: flex;
	flex-wrap: wrap;
	.col-sm-6 {
		display: flex;
		flex-wrap: nowrap;
	}
	.contact-el {
		width: 50%;
		max-width: 450px;
		display: flex;
		margin-bottom: 20px;
	}
	.contacttext {
		padding-left: 15px;
	}
	@media(max-width: 767px) {
		.contact-el {
			width: 100%;
		}
	}
}

.contact-icon {
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: $tc;
	top: 0;
	i.fa {
		color: $white;
		font-size: 30px;
		padding: 11px 10px 10px 10px;
		width: 50px;
		height: 50px;
		text-align: center;
	}
}

.contact-text {
	display: inline-block;
	padding-left: 30px;
	min-height: 60px;
	margin-bottom: 10px;
	line-height: 24px;
	div.contact-text-b-days div {
		display: inline-block;
		&:first-child {
			width: 175px;
		}
	}
}

.contactsmap {
	display: flex;
	& > div {
		width: 50%;
	}
	@media(max-width: 767px) {
		flex-wrap: wrap;
		& > div {
			width: 100%;
		}
	}
}

.search_block {
	overflow: visible;
	form {
		margin: 0;
		button {
			border: none;
			&:focus, &:active {
				outline: none !important;
			}
		}
	}
	.dropdown-menu.autosearch {
		margin: 0;
		width: calc(100% - 75px);
		min-width: 0;
		padding: 0;
		right: 0;
		left: auto;
		display: none;
		border-radius: 0;
		top: 100%;
		li {
			@include transition(0.2s all);
			display: inline-block;
			width: 100%;
			margin-bottom: 5px;
			cursor: pointer;
			line-height: 1;
			position: relative;
			border-bottom: 1px #ebebeb solid;
			&:nth-last-child(-n+1) {
				border: none;
			}
			> div {
				line-height: 1.5;
				display: inline-block;
			}
			a {
				white-space: normal;
				padding: 3px 5px;
			}
			.as-image {
				float: left;
				padding: 5px;
				width: 80px;
				margin-right: 10px;
				img {
					height: auto;
					width: 100%;
				}
			}
			.as-title {
				width: 100%;
				font-size: 16px;
				margin: 10px 0;
				padding-bottom: 20px;
				a {
					color: $black;
				}
			}
			.as-price {
				width: calc(100% - 95px);
				font-size: 14px;
				font-weight: 700;
				position: absolute;
				bottom: 10px;
				left: 95px;
				.price-old {
					font-size: 14px;
					text-decoration: line-through;
					margin-right: 7px;
					color: #777;
				}
			}
			#showall {
				padding: 0 25px;
				display: inline-block;
			}
		}
		@media (max-width: 1199px) {
			width: 300px;
			li {
				.as-image {
					width: 80px;
				}
				.as-title {
					width: 100%;
					font-size: 14px;
					font-weight: 500;
					line-height: 16px;
				}
				.as-price {
					width: calc(100% - 120px);
					left: 95px;
				}
			}
		}
		@media (max-width: 421px) {
			top: calc(100% + 2px);
			width: calc(100vw - 30px);
			left: auto;
		}
	}
}

.producttabs {
	margin: 0;
	background: $lg;
	.tab-header {
		text-align: center;
		text-transform: uppercase;
		font-weight: 500;
		color: $bk;
		font-size: 48px;
		height: 265px;
		background: url('/image/i/tabh.jpg') no-repeat center top;
		background-size: cover;
		span {
			line-height: 1;
			padding-top: 42px;
			display: inline-block;
		}
	}
	.owl-carousel-play {
		margin-top: -140px;
	}
	.owl-dots {
		margin-bottom: 15px;
	}
	.owl-item {
		padding: 15px;
	}
}

#adv_main {
	padding: 50px 0;
	@include flex();
	justify-content: space-between;
	.advmain {
		flex: 1 1 25%;
		text-align: center;
		padding: 0 25px;
		svg {
			width: 100px;
			height: 100px;
			fill: none;
			stroke: #009bde;
			stroke-width: 0.1px;
			stroke-linecap: round;
		}
		h4 {
			font-size: 24px;
			margin: 20px 0;
		}
		p {
			text-align: center;
			color: $bk;
			font-size: 15px;
		}
		.svgcont {
			display: inline-flex;
			border-radius: 100%;
			justify-content: center;
			align-items: center;
		}
	}
}

.homebuilder {
	.catgrid {
		background: $lg;
		@include flex();
		justify-content: space-between;
		padding: 15px 30px;
		flex-wrap: wrap;
		&:after, &:before {
			content: none;
		}
		&_element {
			flex: 1;
			border-radius: 5px;
			background: $wh;
			margin: 7px;
			padding: 15px 10px;
			text-align: center;
			text-transform: uppercase;
			font-size: 18px;
			font-weight: 500;
			line-height: 1.3;
			min-height: 77px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.pav-inner .catgrid_element {
	img {
		display: none;
	}
}

.owl-dots {
	text-align: center;
	.owl-dot {
		display: inline-block;
		margin: 0 5px;
		svg {
			display: inline-block;
			width: 16px;
			height: 22px;
			stroke: none;
			fill: $bk;
			opacity: .3;
			@include fade();
		}
		&.active {
			svg {
				opacity: 1;
			}
		}
	}
}

.products-owl-carousel .product-block {
	margin-bottom: 0;
}

#checkout-form #shipping_methods .radio, #checkout-form #payment_methods .radio {
	margin-left: 0 !important;
}

.radio_custom {
	.list_of_points {
		display: block;
		text-align: left;
		font-size: 14px;
		color: $bk;
		font-weight: 500;
		padding-left: 22px;
	}
	a {
		padding-left: 10px;
		font-size: 18px;
		color: $tc;
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 22px;
		position: relative;
		font-size: 14px;
		font-weight: 500;
		color: $bk;
		margin: 1px 0;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 4px;
			left: 0;
			user-select: none;
			@include size(15px, 15px);
			text-align: center;
			background-color: $wh;
			border: 2px solid $tc;
		}
		&.radio input ~ .input-control__indicator:after {
			border-radius: 50%;
			content: "";
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transform: scale(0.45);
			transform-origin: center center;
			width: 100%;
			background: $tc;
			@include opacity(0);
			@include transition(.1s opacity linear);
		}
		&.radio input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
	@media (max-width: 767px) {
		.input-control, .list_of_points {
			font-size: 14px;
		}
	}
}

#direktoru_link {
	text-align: right;
	position: relative;
	margin-bottom: 5px;
	a {
		position: absolute;
		color: $mg;
		left: 0;
		right: 15px;
		text-decoration: underline;
	}
}

.direktoru {
	.radio_custom {
		cursor: default;
		.input-control__indicator {
			top: 13px;
		}
	}
}

.checkbox_custom {
	a {
		padding-left: 10px;
		font-size: 14px;
		color: $tc;
		&.agree b {
			font-weight: 500;
			padding: 0;
			font-size: 12px;
		}
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 25px;
		line-height: 16px;
		position: relative;
		font-size: 12px;
		font-weight: 500;
		color: $bk;
		margin: 5px 10px 5px 14px;
		text-align: left;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			user-select: none;
			@include size(16px, 16px);
			text-align: center;
			background-color: $white;
			border: 2px $tc solid;
			@include rounded-corners(0px);
		}
		&.checkbox input ~ .input-control__indicator:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 4px;
			transform-origin: center center;
			@include opacity(0);
			@include transition(.1s opacity linear);
			@include transform(translateZ(1px) rotate(45deg));
			border-bottom: $tc 2px solid;
			width: 5px;
			height: 10px;
			border-right: $tc 2px solid;
		}
		&.checkbox input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
}

#button-order {
	margin-top: 15px;
}

.catwidget_header h2 {
	text-align: center;
	font-weight: 900;
	font-size: 60px;
	margin-top: 45px;
	margin-bottom: 30px;
}

.category_widget {
	.img {
		position: relative;
		background: transparent center no-repeat;
		background-size: cover;
		h3 {
			position: absolute;
			text-transform: uppercase;
			text-align: center;
			color: $white;
			display: inline-block;
		}
	}
	.type1 {
		padding-bottom: 50px;
		.img {
			height: 230px;
			margin-bottom: 30px;
			h3 {
				margin: 0;
				height: 60px;
				left: 0;
				right: 0;
				bottom: 0;
				background: transparentize($bk, .3);
				font-weight: 700;
				font-size: 24px;
				padding: 20px 0;
			}
		}
		.descr {
			font-size: 16px;
		}
	}
	.type2 {
		.img {
			height: 21vw;
			@include flex();
			@include fade();
			background-size: 100%;
			align-items: center;
			justify-content: center;
			h3 {
				margin: 0;
				padding: 30px;
				background: transparent;
				font-size: 44px;
				font-weight: 900;
				position: relative;
			}
		}
		&:hover {
			.img {
				background-size: 110%;
			}
		}
	}
}

.product-compare {
	.comparetable {
		.img-thumbnail {
			border: none;
		}
		.table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
			width: 500px;
		}
	}
	.compare_actions {
		.btn {
			width: 150px;
		}
	}
}

#totop {
	position: fixed;
	right: 10px;
	bottom: 10px;
	cursor: pointer;
	width: 41px;
	height: 41px;
	background-color: $tc;
	z-index: 999;
	@include rounded-corners(100%);
	@include transition(200ms all ease-in-out);
	i {
		font-size: 26px;
		color: $wh;
		position: absolute;
		left: 12px;
		top: 5px;
	}
	&:hover {
		background-color: $bk;
	}
}

#coupon_form {
	h4 {
		font-size: 16px;
	}
	#input-coupon {
		border-radius: 5px 0 0 5px;
	}
	#button-coupon {
		padding: 0 16px;
		height: 36px;
		line-height: 34px;
		position: relative;
		left: 0;
		z-index: 0;
		border-radius: 0 5px 5px 0;
	}
}

.maintenance {
	min-height: calc(100vh - 381px);
}

.widget-products {
	.product-grid .product-block {
		width: 100%;
		border-radius: 5px;
		margin: 0;
		overflow: hidden;
	}
}

.product-grid {
	.col-nopadding {
		@include flex();
		flex-wrap: wrap;
		padding: 0;
	}
}

h1 {
	padding: 15px 0;
	text-transform: none;
}

.comparetable {
	padding: 15px;
	font-size: 16px;
	overflow-x: auto;
}

.owl-prev, .owl-next {
	line-height: 1;
	font-size: 30px;
	position: absolute;
	top: calc(50% - 20px);
	padding: 4px 9px;
	width: 30px;
	height: 40px;
	color: $tc;
	@include transition(0.2s ease all);
	background: transparent;
}

.owl-prev {
	left: -16px;
}

.owl-next {
	right: -16px;
	padding: 4px 11px;
}

.mfilter-box {
	.box-heading {
		display: none;
	}
	.box-content {
		border: none;
		.mfilter-heading {
			background: transparent;
			border: none;
			.mfilter-heading-content {
				padding: 20px 0 6px 0;
			}
			.mfilter-head-icon {
				@include size(25px, 20px);
				@include transition(none);
				@include notransform();
				background: url(/image/i/mfminus.png) no-repeat transparent center;
			}
			&.mfilter-collapsed .mfilter-head-icon {
				background-image: url(/image/i/mfplus.png);
			}
		}
		.mfilter-heading-text {
			width: calc(100% - 27px);
			margin-left: 0;
			> span {
				padding-left: 0;
				font-size: 14px;
				text-transform: uppercase;
			}
		}
		.mfilter-col-input {
			padding-right: 8px;
		}
		.mfilter-opts-container {
			border-top: 1px solid $lg;
			padding: 9px 0 5px 0;
			label {
				font-weight: 400;
			}
			.mfilter-counter {
				border-radius: 0;
				background: #acacac;
				&:after {
					content: none;
				}
			}
			input[type="text"] {
				border-radius: 0;
				height: 45px;
			}
			.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
				background: $white;
			}
			.mfilter-slider-slider, #mfilter-price-slider {
				border: $lg 1px solid;
				background: $white;
				margin: 15px 2px 0 5px !important;
				.ui-slider-range {
					background: $lg !important;
				}
				.ui-slider-handle {
					background: $white !important;
					height: 18px !important;
					border: 1px $lg solid !important;
					border-radius: 0;
				}
			}
		}
	}
	.mfilter-tb-as-td {
		vertical-align: bottom;
		font-size: 12px;
	}
}

.selectric-wrapper {
	position: relative;
	cursor: pointer;
}

.selectric-responsive {
	width: 100%;
}

.selectric {
	border: 1px solid $lg;
	border-radius: 0;
	background: $white;
	position: relative;
	overflow: hidden;
	.label {
		display: block;
		white-space: nowrap;
		overflow: visible;
		width: calc(100% - 35px);
		text-overflow: ellipsis;
		margin: 0 25px 0 10px;
		padding: 8px 0;
		font-size: 14px;
		color: $black;
		height: 30px;
		font-weight: 400;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		line-height: 1;
	}
	.button {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 38px;
		line-height: 38px;
		background-color: $white;
		color: $black;
		text-align: center;
		&:after {
			content: '';
			position: absolute;
			@include size(6px, 6px);
			border-bottom: 1px solid $black;
			border-right: 1px solid $black;
			@include transform(rotate(45deg));
			right: 10px;
			top: 10px;
		}
	}
}

.selectric-focus .selectric {
	border-color: #aaaaaa;
}

.selectric-hover .selectric {
	.button {
		color: #a2a2a2;
		&:after {
			border-top-color: #a2a2a2;
		}
	}
}

.selectric-open {
	z-index: 9999;
	.selectric .button::after {
		@include transform(rotate(225deg));
		transform-origin: 4px 5px;
	}
	.selectric-items {
		display: block;
	}
}

.selectric-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	select {
		position: absolute;
		left: -100%;
	}
	&.selectric-is-native {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 10;
		select {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			border: none;
			z-index: 1;
			box-sizing: border-box;
			opacity: 0;
		}
	}
}

.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.selectric-items {
	display: none;
	position: absolute;
	top: 31px;
	right: 0;
	background: $white;
	border: 1px solid $lg;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
	.selectric-scroll {
		height: 100%;
		overflow: auto;
	}
	ul, .selectric-items li {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 14px;
		line-height: 20px;
		min-height: 20px;
	}
	li {
		display: block;
		padding: 10px;
		color: $black;
		cursor: pointer;
		line-height: 16px;
		white-space: nowrap;
		&.selected, &.highlighted, &:hover {
			color: $tc;
		}
	}
	.disabled {
		filter: alpha(opacity=50);
		opacity: 0.5;
		cursor: default !important;
		background: none !important;
		color: #666 !important;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.selectric-group {
		.selectric-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background: none;
			color: #444;
		}
		&.disabled li {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}

.selectric-above .selectric-items {
	top: auto;
	bottom: 100%;
}

.widget-products {
	.owl-item {
		&:hover {
			z-index: 400;
		}
	}
	.owl-next, .owl-prev {
		top: calc(50% - 40px);
	}
}

#request-on-order-form {
	#request-on-order-form fieldset fieldset {
		label:before {
			content: none;
		}
	}
	.btn-buy-product {
		background: $tc !important;
		padding: 0 37px !important;
		color: $white !important;
		@include transition(0.2s all);
		&:hover {
			background: $bk !important;
		}
	}
}

.page-information, .checkout-buy {
	h1 {
		margin-top: 0;
	}
}

.pagescarousel {
	margin: 30px 0;
	.item {
		padding: 0 47px
	}
	h3 {
		margin: 0 0 15px 0;
	}
	.owl-next, .owl-prev {
		top: calc(50% - 20px);
	}
	.owl-prev {
		left: 0;
	}
	.owl-stage {
		@include flex();
		align-items: stretch;
	}
	.owl-item {
		position: relative;
	}
	.pc_wrapper {
		@include flex();
		& > div {
			&.pc_image, &.pc_text {
				flex: 0 0 50%;
			}
			&.pc_text {
				padding-right: 15px;
				p {
					text-align: justify;
					padding-bottom: 46px;
				}
				.btn {
					position: absolute;
					bottom: 0;
				}
			}
			&.pc_image {
				padding-left: 15px;
			}
		}
	}
}

.holiday_works {
	position: absolute !important;
    left: 70px;
    top: 22px !important;
	text-align: center;
	margin-top: 10px;
	border: none !important;
	margin-left: auto !important;
	display: none;
}


.collapse {
	display: none;
	visibility: hidden;

	&.in {
		display: block;
		visibility: visible;
	}
}

tr.collapse.in {
	display: table-row;
}

tbody.collapse.in {
	display: table-row-group;
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	@include transition-property(height, visibility);
	@include transition-duration(.35s);
	@include transition-timing-function(ease);
}

table.warranty {
	margin: 15px 0;
	td {
		border: 1px solid $bk;
		padding: 0 10px;
	}
	p {
		margin: 10px 0;
	}
}

#is_ip .radio_custom .input-control {
	margin-right: 15px;
}