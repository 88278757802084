// banner builder
.banner-wrapper-margin {
	.banner-wrapper {
		margin-bottom: 20px;
	}
}

.image-item-margin {
	.image-item {
		margin-bottom: 20px;
	}
}

.nomargin .box {
	margin-bottom: 0;
}

/* slidelayer */
.layerslider-wrapper {
	overflow: hidden;
	&:hover {
		.tp-rightarrow {
			right: 20px !important;
		}
		.tp-leftarrow {
			left: 20px !important;
		}
	}
}

.tparrows {
	position: absolute;
	top: 50% !important;
	z-index: 20;
	cursor: pointer;
	display: block;
	@include size(45px, 45px);
	@include transition(all 0.3s ease-in-out 0s);
	font-size: 0;
	border-top: 2px solid #00284f;
	border-right: 2px solid #00284f;
	&:hover {
		border-color:#a0a9ad;
	}
	&.tp-rightarrow {
		@include transform(translateZ(1px) rotate(45deg));
		right: 30px !important;
	}
	&.tp-leftarrow {
		@include transform(translateZ(1px) rotate(225deg));
		left: 30px !important;
	}
}

.tp-bullets.simplebullets .bullet:hover,
.tp-bullets.simplebullets .bullet.selected {
	background-color: $theme-color;
}

.tp-bullets.simplebullets.round .bullet:hover,
.tp-bullets.simplebullets.round .bullet.selected,
.tp-bullets.simplebullets.navbar .bullet:hover,
.tp-bullets.simplebullets.navbar .bullet.selected {
	background-color: $theme-color;
}

#modal-agree {
	z-index: 199999;
	margin-right: -17px;
	.modal-header {
		text-transform: uppercase;
	}
	.modal-body {
		#page {
			background: $wh;
			& > *, h1 {
				display: none;
			}
			.main-columns {
				width: auto;
				padding: 0;
				display: block;
				h2 {
					font-size: 20px;
				}
			}
		}
	}
}