body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

// Module fitter
.filter {
	.panel-body {
		padding: 0;
	}
	h6 {
		font-size: $font-size-base + 2;
		font-family: $font-family;
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		margin: 0;
	}
	.list-group-item {
		position: relative;
		padding: 25px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%, 1px);
			background: $border-color;
		}
		&:first-child {
			&:before {
				content: none;
			}
		}
		.icon-v3 {
			@include size(25px, 25px);
			display: inline-block;
			background: url("#{$image-theme-path}icon-3.png") no-repeat;
			margin-right: 5px;
		}
		&:nth-child(2) {
			.icon-v3 {
				background-position: 0 -43px;
			}
		}
		&:nth-child(3) {
			.icon-v3 {
				background-position: 0 -85px;
			}
		}
	}
}

.form-control::-moz-placeholder {
	color: $link-color;
}

/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}

.owl-related {
	padding: 0 0 15px 0 !important;
	.owl-stage-outer {
		padding: 0 !important;
	}
}

.related_noscroll {
	margin-right: -35px;
}

.desc-header-related {
	& > .container {
		position: relative;
	}
	.scroll-button {
		position: absolute;
		right: 0;
		top: 18px;
	}
	@media (max-width: 767px) {
		display: block;
		.scroll-button {
			position: relative;
			margin-bottom: 15px;
		}
	}
}

.main_nl {
	background: $lg;
}





.newsletter {
	background: $tc;
	color: $wh;
	text-align: center;
	position: relative;
	padding: 60px 30px 30px;
	overflow: visible;
	.nl_header {
		color: $wh;
		font-size: 48px;
		font-weight: 800;
		text-transform: uppercase;
		display: inline-block;
		line-height: 1;
		margin-bottom: 20px;
	}
	p {
		font-size: 18px;
	}
	.fl_img {
		position: absolute;
		top: -176px;
		left: -53px;
		max-width: 440px;
		img {
			width: 100%;
		}
	}
	span {
		position: relative;
	}
	.nl_email {
		margin: 40px auto 0 auto;
		width: 30%;
		min-width: 250px;
		input {
			text-align: center;
			width: 100%;
			background: transparent;
			border: none;
			border-bottom: 2px $lg solid;
			@include placeholder(transparentize($wh, .5));
		}
	}
	.checkbox_custom {
		margin-top: 20px;
		.input-control {
			color: $wh;
			a {
				color: transparentize($wh, .5);
				&:hover {
					color: $wh;
				}
			}
		}
	}
	#nl_submit {
		border: 1px solid $wh;
		margin: 20px 0;
		box-shadow: 0 20px 46px rgba(0, 0, 0, .3);
		padding: 0 30px;
	}
	#nl_error, #nl_success {
		line-height: 0;
		position: relative;
		top: 4px;
	}
}